import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/core';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>タルコフスキーについて</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title"></PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <h4>
                  帰還しない放蕩息子
                </h4>
                <p>
                  アンドレイ・アルセーニエヴィッチ・タルコフスキー。
                </p>
                <p>
                  旧ソ連を代表する映画監督。1932年4月4日生れ-1986年12月28日没。享年54歳。『僕の村は戦場だった（イワンの少年時代）』
                  『アンドレイ・ルブリョフ』『惑星ソラリス』『鏡』『ストーカー』『ノスタルジア』『サクリファイス』の7本の長編映画を遺す。<br />
                  父のアルセーニー・タルコフスキーは詩人だが、その処女詩集の出版は、息子の長篇デビュー作『僕の村は戦場だった』の公開と同じ1962年である。
                  息子アンドレイは、「亡命」をした西側の地で、父アルセーニーよりも先に死んでいる。
                  その意味で、この「放蕩息子」は、未だに「帰還」をしていない。
                </p>
                <p>
                 『惑星ソラリス』のファイナルシーンでは、主人公ケルヴィンが地球へ帰還し、家の玄関ポーチにぶっきらぼうに立つ父親に跪坐くが、
                  カメラが上空から俯瞰すると、この地球の家のイメージそのものがソラリスの海に浮かぶ擬態であることが突きつけられる。<br />
                  家も、父も、犬も、木々も湖も高架橋の道路も、すべてが主人公の想念の物質化であり、この衝撃的なシーンにより、
                  ケルヴィンが地球へは帰還せず、ソラリスに留まることを決意し、ソラリスで死ぬことが暗示されていると考えられる。<br />
                  そして、これと同じ構図は、『ノスタルジア』のラスト、
                  主人公アンドレイが異国の地イタリアの干上がった温泉で心臓発作により頽れつつ見る夢幻的な風景、
                  トスカーナの廃墟の聖堂に囲まれたロシアの家のミニアチュアに見いだすこともできるだろう。
                  ここでも主人公は故郷へ帰還せず、引き裂かれた風景の中で死んでいくことが反復されている。<br />
                  『惑星ソラリス』の図書館のシーンで映写される地球の「思い出のビデオ」は、『ノスタルジア』という映画そのものであり、
                  『ノスタルジア』の水浸しの廃墟内で燃やされる詩集の焚き火は、『鏡』の主人公の初恋の女の子が手をかざす暖炉の炎、
                  『ストーカー』のゾーンの遍在する焚き火、
                  そして、『アンドレイ・ルブリョフ』のラストでボリースカが泣き崩れる足元の焚き火へと繋がっている。
                </p>
                <p>
                  しかし、タルコフスキーの「ノスタルジア」は、いわゆる「郷愁」ではない。
                  故郷への想い、懐かしいものへの回帰というものを、タルコフスキー映画の主題として見ることは浅薄な解釈である。
                  7本の映画の一貫したテーマは、時間と記憶に関わる存在論的な問題提起であり、
                  それは、人間とこの世界の存在についての、この世界とこの世界の外部についての、若い、哲学的な思索の集積である。<br />
                  また、映画にあらわれるキリスト教的なモチーフは、タルコフスキーの「信仰」を示すものではない。
                  映画作品では、キリスト教的な世界観は、映像の哲学として包含された一つの表現、エピファニーのようなものとして位置づけられる。
                  彼の著書や手記での「信仰」の表明にも関わらず、映画監督としてのタルコフスキーは、永遠の「放蕩息子」のままである。<br />
                 『サクリファイス』の「家」が燃やされるのは、決してアレクサンデルの「信仰」のためではない。
                 なぜなら、その『サクリファイス』の「家」にしても、やはり、ソラリスの海に浸食されゆく擬態＝記憶の物質化にすぎないからである。
                </p>
                <p>
                  問われていることはこうである。
                </p>
                <p>
                  ソラリスとはなにか、ソラリスそのものを描くことはできるのか、
                  この世界は本当に存在しているのか、この世界の外部はどこにあるのか、人は、この世界に外部があると信じ切ることはできるのか。
                </p>
                <p>
                  このブログでは、以上の観点から、折々に記事を書き、考えを進めていきたい。
                </p>
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
